<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="dataFromDb"
      item-key="id"
      class="elevation-1"
      :search="search"
      :loading="loading"
      :locale="$i18n.locale"
    >
      <template
        v-slot:top
      >
        <div class="row">
          <div class="col-8">
            <div class="container">
              <v-btn
                v-if="$checkAbility('USER_CREATE')"
                outlined
                color="primary"
                @click="goToUserCreatePage()"
              >
                {{ $t('UserPage.Create') }}
              </v-btn>
            </div>
          </div>
          <div class="col-4">
            <v-text-field
              v-model="search"
              :label="$t('LblSearch')"
              class="mx-4"
            ></v-text-field>
          </div>
        </div>
      </template>

      <template v-slot:item.role="{item}">
        <span>{{ $t(`UserPage.Roles.${item.role}`) }}</span>
      </template>

      <template #[`item.status`]="{item}">
        <v-row>
          <v-col>
            <v-chip
              v-show="item.status === 'active'"
              color="success"
            >
              {{ $t('UserPage.Statuses.Active') }}
            </v-chip>
            <v-chip
              v-show="item.status === 'inactive'"
              color="error"
            >
              {{ $t('UserPage.Statuses.Inactive') }}
            </v-chip>
          </v-col>
        </v-row>
      </template>
      <template #[`item.actions`]="{item}">
        <v-row>
          <v-col>
            <v-menu
              top
              offset-x
              offset-y
            >
              <template
                v-slot:activator="{ on, attrs }"
              >
                <v-btn
                  v-if="$checkAbility('USER_UPDATE') && $checkAbility('USER_DELETE') && item.id !== $getUserId()"
                  color="primary"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>

              <v-list>
                <template>
                  <v-list-item
                    v-if="$checkAbility('USER_UPDATE')"
                    :to="{name: 'user-edit', params: { user_id: item.id } }"
                  >
                    <v-list-item-icon class="mr-3">
                      <v-icon v-text="icons.mdiPencil"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('UserPage.Edit') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="$checkAbility('LINK_USERS')"
                    :to="{name: 'users-link', params: { user_id: item.id } }"
                    :disabled="!checkRoleAvailable(item.role)"
                  >
                    <v-list-item-icon class="mr-3">
                      <v-icon v-text="icons.mdiLinkVariant"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('UserPage.LinkUsers') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="$checkAbility('USER_DELETE')"
                    @click="openWarningDialog(item.id)"
                  >
                    <v-list-item-icon class="mr-3">
                      <v-icon v-text="icons.mdiTrashCanOutline"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('UserPage.Delete') }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="showMessage"
      :timeout="timeout"
      :color="color"
    >
      {{ messageText }}
    </v-snackbar>
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('UserPage.Warning') }}
        </v-card-title>
        <v-card-text>{{ $t('UserPage.WarningText') }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteDialog = false"
          >
            {{ $t('UserPage.No') }}
          </v-btn>
          <v-btn
            color="success"
            text
            @click="deleteUser()"
          >
            {{ $t('UserPage.Yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="сanNotDeleteDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('UserPage.Warning') }}
        </v-card-title>
        <v-card-text>{{ $t('UserPage.WarningCantDeleteText') }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="сanNotDeleteDialog = false"
          >
            {{ $t('BtnClose') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { mdiPencil, mdiLinkVariant, mdiTrashCanOutline, mdiDotsVertical } from '@mdi/js'
import qs from 'qs'

export default {
  data() {
    return {
      dataFromDb: [],
      showMessage: false,
      timeout: 5000,
      color: 'success',
      messageText: '',
      deleteDialog: false,
      сanNotDeleteDialog: false,
      search: '',
      loading: false,
      availabledRoles: [],
      icons: {
        mdiPencil,
        mdiLinkVariant,
        mdiTrashCanOutline,
        mdiDotsVertical,
      },
      userToDelete: 0,
    }
  },
  computed: {
    headers() {
      return [
        { text: 'ID', value: 'id' },
        { text: this.$t('UserPage.UserName'), value: 'name' },
        { text: this.$t('UserPage.Email'), value: 'email' },
        { text: this.$t('UserPage.Role'), value: 'role' },
        { text: this.$t('UserPage.Status'), value: 'status' },
        { text: this.$t('UserPage.Actions'), value: 'actions' },
      ]
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    goToUserCreatePage() {
      this.$router.push({ name: 'user-create' })

      // this.$router.push({ name: 'campaigns-list', params: { advertiser_id: advertiserId } })
    },
    getData() {
      this.loading = true
      const params = {
        params: {
          userId: this.$getUserId(),
        },
        paramsSerializer: param => qs.stringify(param, { arrayFormat: 'repeat' }),
      }

      // this.$http.get('/advertisers/table_data', params) `${this.$apisBaseURL}/publisher/item`
      this.$http
        .get(`${this.$apiBaseURL}/user/all`, params)
        .then(response => {
          this.dataFromDb = response.data !== null ? response.data : []
        })
        .then(() => {
          this.$http.get(`${this.$apiBaseURL}/roles/to/link`, params).then(response => {
            this.availabledRoles = response.data !== null ? response.data : []
            this.loading = false
          })
        })
    },
    checkRoleAvailable(role) {
      return !!this.availabledRoles.find(r => r.name === role)
    },
    deleteUser() {
      this.loading = true
      this.$http
        .delete(`${this.$apiBaseURL}/user`, { params: { id: this.userToDelete } })
        .then(() => {
          this.messageText = this.$t('UserPage.UserDeleted')
          this.showMessage = true
          this.loading = false
          this.deleteDialog = false
          this.getData()
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          this.showMessage = true
          this.messageText = error

          // console.error('Упс, не получилось войти!')
          console.log('error :>> ', error.response)

          // errorMessages.value = error.response.data.error
        })
    },
    openWarningDialog(userId) {
      this.userToDelete = userId
      this.$http.get(`${this.$apiBaseURL}/user/details`, { params: { userId } }).then(response => {
        const campaignsCount = response.data !== null ? response.data.campaignsCount : 0
        const userRole = response.data !== null ? response.data.role : ''
        if (campaignsCount > 0 && userRole === 'ADVERTISERS') {
          this.сanNotDeleteDialog = true

          return
        }
        this.deleteDialog = true
      })
    },
  },
}
</script>
